import type { C9Map } from '$lib/models/object.models';
import type { PageTagValues } from '$lib/models/page.models';

export const APP_RAIL_TYPE = {
    home: 'home',
    info: 'info',
    providers: 'providers',
};

export const APP_RAIL_PROPERTIES = {
    home: {
        name: 'none',
    },
    info: {
        name: 'nav.info',
    },
    providers: {
        name: 'nav.providers',
    },
};

export const INFO_PAGES = [
    { name: 'nav.about', path: '/despre-noi' },
    { name: 'nav.services', path: '/servicii' },
    { name: 'nav.posts', path: '/posts' },
    { name: 'nav.contact', path: '/contact' },
];

export const GROUP_PAGES: C9Map<string> = {
    'consultanta-administrare': 'admin',
    'agentii': 'agencies',
    'arte-mestesuguri': 'arts_craft',
    'biserici': 'churches',
    'comert': 'commerce',
    'educatie-cultura': 'educational',
    'servicii-evenimente': 'entertainment',
    'sanatate-ingrijire': 'health',
    'servicii-domiciliu': 'home',
    'reparatii-instalari': 'improve',
    'sport-divertisment': 'sports',
    'transportatori': 'transport',
};

export const C9_SERVICE_GROUP_PAGES: C9Map<PageTagValues> = {
    'service.home.cleaning': {
        hover: 'home.image.hover.cleaning',
        imagePath: '/images/home/cards/11_cleanliness-2799459_1920.jpg',
        keywords: '',
        seoDescription: 'seo.home.cleaning.description',
        seoImgPath: '/images/seo/curatenie-seo.jpg',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.home.cleaning.title',
        urlPath: '/servicii-domiciliu/cleaning',
    },
    'service.home.babysitting': {
        hover: 'home.image.hover.babysitting',
        imagePath: '/images/home/cards/7_baby-623417_1920.jpg',
        keywords: '',
        seoDescription: 'seo.home.babysitting.description',
        seoImgPath: '/images/seo/babysitting-seo.jpg',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.home.babysitting.title',
        urlPath: '/servicii-domiciliu/babysitting',
    },

    'service.admin.accountant': {
        hover: 'home.image.hover.accountant',
        imagePath: '/images/home/cards/14_income-tax-4097292_1920.jpg',
        keywords: '',
        seoDescription: 'seo.admin.accountant.description',
        seoImgPath: '/images/seo/contabilitate-seo.jpg',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.admin.accountant.title',
        urlPath: '/consultanta-administrare/contabilitate',
    },
    'service.admin.consulting': {
        keywords: '',
        seoDescription: 'seo.admin.consulting.description',
        seoImgPath: '/images/seo/consultanta-seo.jpg',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.admin.consulting.title',
        urlPath: '/consultanta-administrare/consultanta',
    },
    'service.admin.finance': {
        keywords: '',
        seoDescription: 'seo.admin.finance.description',
        seoImgPath: '/images/seo/finante-seo.jpg',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.admin.finance.title',
        urlPath: '/consultanta-administrare/finante',
    },
    'service.admin.insurance': {
        keywords: '',
        seoDescription: 'seo.admin.insurance.description',
        seoImgPath: '/images/seo/asigurari-seo.jpg',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.admin.insurance.title',
        urlPath: '/consultanta-administrare/asigurari',
    },
    'service.admin.juridical': {
        hover: 'home.image.hover.juridical',
        imagePath: '/images/home/cards/10_hammer-719066_1920.jpg',
        keywords: '',
        seoDescription: 'seo.admin.juridical.description',
        seoImgPath: '/images/seo/servicii-juridice-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.admin.juridical.title',
        urlPath: '/consultanta-administrare/servicii-juridice',
    },
    'service.admin.lawyer': {
        keywords: '',
        seoDescription: 'seo.admin.lawyer.description',
        seoImgPath: '/images/seo/avocat-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.admin.lawyer.title',
        urlPath: '/consultanta-administrare/avocat',
    },
    'service.admin.notary': {
        keywords: '',
        seoDescription: 'seo.admin.notary.description',
        seoImgPath: '/images/seo/notar-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.admin.notary.title',
        urlPath: '/consultanta-administrare/notar',
    },
    'service.admin.translation': {
        keywords: '',
        seoDescription: 'seo.admin.translation.description',
        seoImgPath: '/images/seo/traduceri-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.admin.translation.title',
        urlPath: '/consultanta-administrare/traduceri',
    },
    'service.admin.advertising': {
        keywords: '',
        seoDescription: 'seo.admin.advertising.description',
        seoImgPath: '/images/seo/pr-promo-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.admin.advertising.title',
        urlPath: '/consultanta-administrare/pr-publicitate',
    },

    'service.improve.mechanic': {
        keywords: '',
        seoDescription: 'seo.improve.mechanic.description',
        seoImgPath: '/images/seo/autor-reparatii-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.improve.mechanic.title',
        urlPath: '/reparatii-instalari/auto-moto-velo',
    },
    'service.improve.building': {
        hover: 'home.image.hover.building',
        imagePath: '/images/home/cards/8_tools-864983_1920.jpg',
        keywords: '',
        seoDescription: 'seo.improve.building.description',
        seoImgPath: '/images/seo/constructii-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.improve.building.title',
        urlPath: '/reparatii-instalari/constructii',
    },
    'service.improve.electronic': {
        keywords: '',
        seoDescription: 'seo.improve.electronic.description',
        seoImgPath: '/images/seo/electrocasnice-echipamente-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.improve.electronic.title',
        urlPath: '/reparatii-instalari/electrocasnice-echipamente',
    },
    'service.improve.computer': {
        keywords: '',
        seoDescription: 'seo.improve.computer.description',
        seoImgPath: '/images/seo/electronice-si-calculatoare-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.improve.computer.title',
        urlPath: '/reparatii-instalari/electronice-calculatoare',
    },
    'service.improve.various': {
        keywords: '',
        seoDescription: 'seo.improve.various.description',
        seoImgPath: '/images/seo/reparatii-diverse-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.improve.various.title',
        urlPath: '/reparatii-instalari/diverse',
    },

    'service.educational.activities': {
        hover: 'home.image.hover.educational',
        imagePath: '/images/home/cards/1_school-1782427_1920.jpg',
        keywords: '',
        seoDescription: 'seo.educational.activities.description',
        seoImgPath: '/images/seo/activitati-extrascolare-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.educational.activities.title',
        urlPath: '/educatie-cultura/activitati-extra-curiculare',
    },
    'service.educational.courses': {
        keywords: '',
        seoDescription: 'seo.educational.courses.description',
        seoImgPath: '/images/seo/cursuri-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.educational.courses.title',
        urlPath: '/educatie-cultura/cursuri',
    },
    'service.educational.schools': {
        keywords: '',
        seoDescription: 'seo.educational.schools.description',
        seoImgPath: '/images/seo/educatie-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.educational.schools.title',
        urlPath: '/educatie-cultura/scoli',
    },
    'service.educational.workshops': {
        keywords: '',
        seoDescription: 'seo.educational.workshops.description',
        seoImgPath: '/images/seo/ateliere-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.educational.workshops.title',
        urlPath: '/educatie-cultura/ateliere',
    },

    'service.entertainment.events': {
        keywords: '',
        seoDescription: 'seo.entertainment.events.description',
        seoImgPath: '/images/seo/organizare-evenimente-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.entertainment.events.title',
        urlPath: '/servicii-evenimente/organizare-evenimente',
    },
    'service.entertainment.eating': {
        hover: 'home.image.hover.eating',
        imagePath: '/images/home/cards/6_wedding-1937022_1920.jpg',
        keywords: '',
        seoDescription: 'seo.entertainment.eating.description',
        seoImgPath: '/images/seo/localuri.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.entertainment.eating.title',
        urlPath: '/servicii-evenimente/localuri',
    },
    'service.entertainment.catering': {
        hover: 'home.image.hover.catering',
        imagePath: '/images/home/cards/9_apple-pie-3723444_1920.jpg',
        keywords: '',
        seoDescription: 'seo.entertainment.catering.description',
        seoImgPath: '/images/seo/catering-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.entertainment.catering.title',
        urlPath: '/servicii-evenimente/catering',
    },
    'service.entertainment.music': {
        keywords: '',
        seoDescription: 'seo.entertainment.music.description',
        seoImgPath: '/images/seo/muzica-evenimente-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.entertainment.music.title',
        title: 'service.entertainment.music-seo',
        urlPath: '/servicii-evenimente/muzica',
    },
    'service.entertainment.decor': {
        keywords: '',
        seoDescription: 'seo.entertainment.decor.description',
        seoImgPath: '/images/seo/decor-evenimente.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.entertainment.decor.title',
        urlPath: '/servicii-evenimente/decor',
    },
    'service.entertainment.photovideo': {
        keywords: '',
        seoDescription: 'seo.entertainment.photovideo.description',
        seoImgPath: '/images/seo/foto-video-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.entertainment.photovideo.title',
        urlPath: '/servicii-evenimente/photo-video',
    },
    'service.entertainment.various': {
        keywords: '',
        seoDescription: 'seo.entertainment.various.description',
        seoImgPath: '/images/seo/diverse-evenimente.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.entertainment.various.title',
        urlPath: '/servicii-evenimente/diverse',
    },

    'service.health.doctors': {
        hover: 'home.image.hover.health',
        imagePath: '/images/home/cards/2_ecg-1953179_1920.jpg',
        keywords: '',
        seoDescription: 'seo.health.doctors.description',
        seoImgPath: '/images/seo/medici-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.health.doctors.title',
        urlPath: '/sanatate-ingrijire/medici',
    },
    'service.health.clinics': {
        keywords: '',
        seoDescription: 'seo.health.clinics.description',
        seoImgPath: '/images/seo/clinici-si-spitale-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.health.clinics.title',
        urlPath: '/sanatate-ingrijire/clinici-spitale',
    },
    'service.health.spa': {
        keywords: '',
        seoDescription: 'seo.health.spa.description',
        seoImgPath: '/images/seo/spa-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.health.spa.title',
        urlPath: '/sanatate-ingrijire/spa',
    },
    'service.health.beauty': {
        keywords: '',
        seoDescription: 'seo.health.beauty.description',
        seoImgPath: '/images/seo/girl.jpg',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.health.beauty.title',
        urlPath: '/sanatate-ingrijire/beauty',
    },
    'service.health.products': {
        keywords: '',
        seoDescription: 'seo.health.products.description',
        seoImgPath: '/images/seo/produse-ingrijire-sanatate-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.health.beauty.title',
        urlPath: '/sanatate-ingrijire/produse',
    },
    'service.health.zen': {
        keywords: '',
        seoDescription: 'seo.health.zen.description',
        seoImgPath: '/images/seo/yoga.jpg',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.health.zen.title',
        urlPath: '/sanatate-ingrijire/zen',
    },
    'service.health.various': {
        keywords: '',
        seoDescription: 'seo.health.various.description',
        seoImgPath: '/images/seo/diverse-sanatate-si-ingrijire-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.health.various.title',
        urlPath: '/sanatate-ingrijire/diverse',
    },

    'service.transport.packages': {
        keywords: '',
        seoDescription: 'seo.transport.packages.description',
        seoImgPath: '/images/seo/transport-colete-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.transport.packages.title',
        urlPath: '/transportatori/colete',
    },
    'service.transport.passengers': {
        hover: 'home.image.hover.transport',
        imagePath: '/images/home/cards/3_vw-bully-1868890_1920.jpg',
        keywords: '',
        seoDescription: 'seo.transport.passengers.description',
        seoImgPath: '/images/seo/transport-pasageri-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.transport.passengers.title',
        urlPath: '/transportatori/pasageri',
    },
    'service.transport.various': {
        keywords: '',
        seoDescription: 'seo.transport.various.description',
        seoImgPath: '/images/seo/diverse-transport.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.transport.various.title',
        urlPath: '/transportatori/diverse',
    },

    'service.arts_craft.arts': {
        keywords: '',
        seoDescription: 'seo.arts_craft.arts.description',
        seoImgPath: '/images/seo/creatie-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.arts_craft.arts.title',
        urlPath: '/arte-mestesuguri/arte',
    },
    'service.arts_craft.handmade': {
        keywords: '',
        seoDescription: 'seo.arts_craft.handmade.description',
        seoImgPath: '/images/seo/handmade-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.arts_craft.handmade.title',
        urlPath: '/arte-mestesuguri/hand-made',
    },
    'service.arts_craft.gifts': {
        keywords: '',
        seoDescription: 'seo.arts_craft.gifts.description',
        seoImgPath: '/images/seo/cadour-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.arts_craft.gifts.title',
        urlPath: '/arte-mestesuguri/cadouri',
    },
    'service.arts_craft.various': {
        keywords: '',
        seoDescription: 'seo.arts_craft.various.description',
        seoImgPath: '/images/seo/diverse-creatii-mestesuguri-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.arts_craft.various.title',
        urlPath: '/arte-mestesuguri/diverse',
    },

    'service.sports.clubs': {
        keywords: '',
        seoDescription: 'seo.sports.clubs.description',
        seoImgPath: '/images/seo/cluburi-sportive.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.sports.clubs.title',
        urlPath: '/sport-divertisment/cluburi',
    },
    'service.sports.recreation': {
        keywords: '',
        seoDescription: 'seo.sports.recreation.description',
        seoImgPath: '/images/seo/centre-agrement.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.sports.recreation.title',
        urlPath: '/sport-divertisment/centre-agrement',
    },
    'service.sports.various': {
        keywords: '',
        seoDescription: 'seo.sports.various.description',
        seoImgPath: '/images/seo/sport-diverse-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.sports.various.title',
        urlPath: '/sport-divertisment/diverse',
    },

    'service.commerce.shops': {
        keywords: '',
        seoDescription: 'seo.commerce.shops.description',
        seoImgPath: '/images/seo/magazine-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.commerce.shops.title',
        urlPath: '/comert/magazine',
    },
    'service.commerce.markets': {
        keywords: '',
        seoDescription: 'seo.commerce.markets.description',
        seoImgPath: '/images/seo/piete-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.commerce.markets.title',
        urlPath: '/comert/piete',
    },
    'service.commerce.products': {
        keywords: '',
        seoDescription: 'seo.commerce.products.description',
        seoImgPath: '/images/seo/produse-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.commerce.products.title',
        urlPath: '/comert/produse',
    },
    'service.agencies.jobs': {
        keywords: '',
        seoDescription: 'seo.agencies.jobs.description',
        seoImgPath: '/images/seo/locuri-de-munca-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.agencies.jobs.title',
        urlPath: '/agentii/jobs',
    },
    'service.agencies.estate': {
        hover: 'home.image.hover.estate',
        imagePath: '/images/home/cards/5_kitchen-2565105_1920.jpg',
        keywords: '',
        seoDescription: 'seo.agencies.estate.description',
        seoImgPath: '/images/seo/imobiliar-agentii.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.agencies.estate.title',
        urlPath: '/agentii/real-estate',
    },

    'service.churches.church': {
        seoDescription: 'seo.churches.description',
        seoImgPath: '/images/seo/biserici-seo.png',
        seoImgWidth: 600,
        seoImgHeight: 600,
        seoTitle: 'seo.churches.title',
        urlPath: '/biserici',
    },
};

export const C9_CATEGORY_SEO: C9Map<PageTagValues> = Object.keys(C9_SERVICE_GROUP_PAGES).reduce(
    (collector, key) => {
        const categoryObj: PageTagValues = {
            ...C9_SERVICE_GROUP_PAGES[key],
            seoTitle: C9_SERVICE_GROUP_PAGES[key].seoTitle ?? key,
        };
        return {
            ...collector,
            [categoryObj.urlPath]: categoryObj,
        };
    },
    {
        '/': {
            keywords: '',
            seoDescription: 'home.description',
            seoImgPath: '/images/seo/home-casa9.png',
            seoImgWidth: 966,
            seoImgHeight: 668,
            seoTitle: 'home.title',
            urlPath: '/',
        },
        '/contact': {
            seoDescription: 'contact.description',
            seoImgPath: '/images/seo/home-casa9.png',
            seoImgWidth: 966,
            seoImgHeight: 668,
            seoTitle: 'contact.label',
            urlPath: '/contact',
        },
        '/despre-noi': {
            seoDescription: 'home.description',
            seoImgPath: '/images/seo/home-casa9.png',
            seoImgWidth: 966,
            seoImgHeight: 668,
            seoTitle: 'home.title',
            urlPath: '/despre-noi',
        },
        '/posts': {
            seoDescription: 'blog.seo.description',
            seoImgPath: '/images/seo/blog-seo.jpg',
            seoImgWidth: 600,
            seoImgHeight: 600,
            seoTitle: 'blog.seo.title',
            urlPath: '/posts',
        },
        '/servicii': {
            seoDescription: 'servicii.seo.description',
            seoImgPath: '/images/seo/servicii-seo.jpg',
            seoImgWidth: 600,
            seoImgHeight: 600,
            seoTitle: 'servicii.seo.title',
            urlPath: '/servicii',
        },
    },
);

export const PAGES_WITHOUT_HEADER: string[] = ['/', '/contact', '/despre-noi', '/posts', '/servicii'];
